import { Button, TextField } from "@material-ui/core";
import React from "react";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import DocumentUploader from "./common/ImageUploader";
import configureStore from "../../../store/configureStore";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

export default function AddStartup() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;

  const [state, setState] = React.useState({
    name: "",
    image: "",
  });

  const handleChange = (event) => {
    //   setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  const handleUploadChange = async () => {
    console.log("handleUploadChange");
    //   setState({ ...state, image: store.getState().document.documentUrl });
  };

  const handleSubmit = () => {
    console.log("Handle submit");
    //  dispatch(create_category(state, activeOuid));
    // history.push(`/${activeOuid}/`);
  };

  return (
    <div>
      <HeaderInner pageTitle="AddStartup" />
      <div className="container--narrow AppPage mt">
        <DocumentUploader
          activeOuid={activeOuid}
          activeOuidChain={activeOuidChain}
          defaultState={state.upload}
          handleUploadChange={handleUploadChange}
        />
        <TextField
          id="name"
          label="Startup Name"
          fullWidth
          variant="outlined"
          className={classes.input}
          onChange={handleChange}
        />
        <TextField
          id="name"
          label="Desscription"
          fullWidth
          variant="outlined"
          className={classes.input}
          onChange={handleChange}
          multiline
          minRows={4}
        />
        <Button
          className="primary_btn"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          POST
        </Button>
      </div>
    </div>
  );
}
