export const RESET_STATE = "RESET_STATE";

export const CREATE_CHANNEL_REQUESTED = "CREATE_CHANNEL_REQUESTED";
export const CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS";
export const CREATE_CHANNEL_ERRORED = "CREATE_CHANNEL_ERRORED";
export const GET_ALL_CHANNEL_REQUESTED = "GET_ALL_CHANNEL_REQUESTED";
export const GET_ALL_CHANNEL_SUCCESS = "GET_ALL_CHANNEL_SUCCESS";
export const GET_ALL_CHANNEL_ERRORED = "GET_ALL_CHANNEL_ERRORED";
export const GET_CHAT_BY_CHANNEL_REQUESTED = "GET_CHAT_BY_CHANNEL_REQUESTED";
export const GET_CHAT_BY_CHANNEL_SUCCESS = "GET_CHAT_BY_CHANNEL_SUCCESS";
export const GET_CHAT_BY_CHANNEL_ERRORED = "GET_CHAT_BY_CHANNEL_ERRORED";

export const CREATE_POST_REQUESTED = "CREATE_POST_REQUESTED";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERRORED = "CREATE_POST_ERRORED";
export const GET_POST_REQUESTED = "GET_POST_REQUESTED";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_ERRORED = "GET_POST_ERRORED";
export const GET_POST_DETAILS_REQUESTED = "GET_POST_DETAILS_REQUESTED";
export const GET_POST_DETAILS_SUCCESS = "GET_POST_DETAILS_SUCCESS";
export const GET_POST_DETAILS_ERRORED = "GET_POST_DETAILS_ERRORED";
export const UPDATE_POST_REQUESTED = "UPDATE_POST_REQUESTED";
export const UPDATE_POST_SUCCESS = "UPDATE_POST_SUCCESS";
export const UPDATE_POST_ERRORED = "UPDATE_POST_ERRORED";
export const DELETE_POST_REQUESTED = "DELETE_POST_REQUESTED";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERRORED = "DELETE_POST_ERRORED";
export const RESET_POST = "RESET_POST";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";
export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";
export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";
