import React from "react";
import { Link } from "react-router-dom";
import profimg from "../images/pro.png";

export default function CompanyCard() {
  return (
    <>
      <Link to="/" className="company_card_wrap mb">
        <div className="company_card_img">
          <img src={profimg} />
          {/* <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.contactFullName &&
                props.item.contactFullName.charAt(0)}
            </Avatar> */}
        </div>
        <div className="company_card_details_wrap">
          <div className="company_card_details_top">
            <div className="company_card_details_name">Company Name</div>
            <span className="company_card_details_tag">Incubator</span>
          </div>
          <p className="company_card_details_desc">
            At vero eos et accusamus et iusto
          </p>
        </div>
      </Link>
      <Link to="/" className="company_card_wrap mb">
        <div className="company_card_img">
          <img src={profimg} />
          {/* <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.contactFullName &&
                props.item.contactFullName.charAt(0)}
            </Avatar> */}
        </div>
        <div className="company_card_details_wrap">
          <div className="company_card_details_top">
            <div className="company_card_details_name">Company Name</div>
            <span className="company_card_details_tag">Incubator</span>
          </div>
          <p className="company_card_details_desc">
            At vero eos et accusamus et iusto
          </p>
        </div>
      </Link>
      <Link to="/" className="company_card_wrap mb">
        <div className="company_card_img">
          <img src={profimg} />
          {/* <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.contactFullName &&
                props.item.contactFullName.charAt(0)}
            </Avatar> */}
        </div>
        <div className="company_card_details_wrap">
          <div className="company_card_details_top">
            <div className="company_card_details_name">Company Name</div>
            <span className="company_card_details_tag">Incubator</span>
          </div>
          <p className="company_card_details_desc">
            At vero eos et accusamus et iusto
          </p>
        </div>
      </Link>
    </>
  );
}
