import {
  CREATE_POST_REQUESTED,
  CREATE_POST_SUCCESS,
  CREATE_POST_ERRORED,
  GET_POST_REQUESTED,
  GET_POST_SUCCESS,
  GET_POST_ERRORED,
  GET_POST_DETAILS_REQUESTED,
  GET_POST_DETAILS_SUCCESS,
  GET_POST_DETAILS_ERRORED,
  UPDATE_POST_REQUESTED,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERRORED,
  DELETE_POST_REQUESTED,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERRORED,
  RESET_POST,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  posts: [],
  postDetails: {},
  error: {},
};

export function post(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_POST_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_POST_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_POST_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_POST_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_POST_SUCCESS:
      return {
        ...state,
        posts: payload,
        showGlobalLoader: false,
      };

    case GET_POST_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_POST_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_POST_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        postDetails: payload,
      };

    case GET_POST_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_POST_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_POST_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        postDetails: payload,
      };

    case UPDATE_POST_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_POST_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_POST_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_POST_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case RESET_POST:
      return {
        ...state,
        showGlobalLoader: false,
        posts: [],
      };

    default:
      return state;
  }
}
