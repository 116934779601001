import { IconButton, InputBase, Paper } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import HeaderInner from "./HeaderInner";
import NavigationSharpIcon from "@material-ui/icons/NavigationSharp";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import { create_channel, get_chat_by_channel } from "./actions/action-chat";
import Sockette from "sockette";

let ws = null;

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginRight: 15,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 14,
  },
  iconButton: {
    padding: 10,
  },
}));

export default function ChatScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { channelChat, showGlobalLoader } = useSelector((state) => state.chat);
  let url;
  let chatId;
  url = window.location.pathname.split("/");
  chatId = url[3];
  const params = new URL(window.location.href).searchParams;
  const actor = params.get("actor");
  console.log("actor", actor);

  const { details } = store.getState().myProfile;
  const username = details.data.username;
  const [messageList, setMessageList] = useState([]);
  const [connection, SetConnection] = useState(false);
  const [badge, setBadge] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [state, setState] = React.useState({
    message: "",
  });
  if (!connection) {
    ws = new Sockette(
      `wss://tqwp8ee9ii.execute-api.eu-west-2.amazonaws.com/dev?channel=${chatId}`,
      {
        timeout: 5e3,
        maxAttempts: 1,
        onopen: (e) => onConnected(e),
        onmessage: (e) => onMessageReceied(e),
        onreconnect: (e) => console.log("Reconnecting...", e),
        onmaximum: (e) => console.log("Stop Attempting!", e),
        onclose: (e) => onCloseConnection(e),
        onerror: (e) => console.log("Error:", e),
      }
    );
  }

  const onConnected = (e) => {
    console.log("Connected!", e);
    SetConnection(true);
  };
  const onCloseConnection = (e) => {
    console.log("Close!", e);
    SetConnection(false);
  };
  // const [channelState, setChannelStateState] = React.useState({
  //   channel: "kalpaka-system-general",
  //   actors: ["kalpaka", "system"],
  // });

  function getQueryVariable(variable) {}

  const handleChange = (event) => {
    console.log("event.target.name", event.target.name);
    console.log("event.target.value", event.target.value);
    console.log("event.target.id", event.target.id);
    console.log("event", event);
    if (event.target.id) {
      setState({ ...state, [event.target.id]: event.target.value });
    }
  };
  console.log("state", state);

  const onMessageWasSent = (message) => {
    console.log("newMessage", message);
    const { jwtToken } = store.getState().jwt.api.credentials.idToken;
    ws.json({
      action: "message",
      data: { text: message },
      token: jwtToken,
      ouid: activeOuid,
    });
    console.log("Message send");
  };

  const onMessageReceied = (data) => {
    console.log("Recieved message", data);
    // const { author, type, data: messageData } = JSON.parse(data);
    const author = "vikesh";
    const type = "text";
    const isMe = username === author ? "me" : "them";
    if (!isOpen) {
      setBadge(+badge + 1);
    }
    setMessageList([
      ...messageList,
      {
        author: isMe,
        type,
        data: { text: data },
      },
    ]);
  };

  const handleSubmit = (chatId, actor) => {
    console.log("actor", actor);
    const channelState = {
      channel: chatId,
      actors: [`${activeOuid.toLowerCase()}`, `${actor.toLowerCase()}`],
    }
    console.log("channelState", channelState)
    console.log("channelChat", channelChat)
    onMessageWasSent(state.message);
    dispatch(create_channel(channelState, activeOuid));
    // if(channelChat === null){
    //   dispatch(create_channel(channelState, activeOuid));
    // }
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_chat_by_channel(chatId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);
  return (
    <div className="HrmAppPage container--narrow mt">
      <HeaderInner />
      {/* <div className="chat_screen_wrap">
        <div className="chat_screen_left_message_wrap">
          <span className="chat_screen_message_status">Message/Receive</span>
          <p className="chat_screen_left_message">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            fringilla quam eu faci lisis mollis.
          </p>
        </div>
        <div className="chat_screen_right_message_wrap">
          <span className="chat_screen_message_status">Message/Receive</span>
          <p className="chat_screen_right_message">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            fringilla quam eu faci lisis mollis.
          </p>
        </div>
        <div className="chat_screen_left_message_wrap">
          <span className="chat_screen_message_status">Message/Receive</span>
          <p className="chat_screen_left_message">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            fringilla quam eu faci lisis mollis.
          </p>
        </div>
        <div className="chat_screen_right_message_wrap">
          <span className="chat_screen_message_status">Message/Receive</span>
          <p className="chat_screen_right_message">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donecs the
            fringilla quam eu faci lisis mollis.
          </p>
        </div>
      </div> */}
      <div className="chat_send_wrap">
        <Paper component="div" className={classes.root}>
          <InputBase
            className={classes.input}
            id="message"
            placeholder="Message here..."
            inputProps={{ "aria-label": "search" }}
            onChange={handleChange}
          />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="send"
            onClick={(event) => handleSubmit(chatId, actor)}
          >
            <NavigationSharpIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
}
