import {
  CREATE_POST_REQUESTED,
  CREATE_POST_SUCCESS,
  CREATE_POST_ERRORED,
  GET_POST_REQUESTED,
  GET_POST_SUCCESS,
  GET_POST_ERRORED,
  GET_POST_DETAILS_REQUESTED,
  GET_POST_DETAILS_SUCCESS,
  GET_POST_DETAILS_ERRORED,
  UPDATE_POST_REQUESTED,
  UPDATE_POST_SUCCESS,
  UPDATE_POST_ERRORED,
  DELETE_POST_REQUESTED,
  DELETE_POST_SUCCESS,
  DELETE_POST_ERRORED,
  RESET_POST,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_post_requested = () => ({
  type: CREATE_POST_REQUESTED,
});

export const create_post_success = () => ({
  type: CREATE_POST_SUCCESS,
});

export const create_post_errored = (error) => ({
  type: CREATE_POST_ERRORED,
  error,
});

export const get_post_requested = () => ({
  type: GET_POST_REQUESTED,
});

export const get_post_success = (payload) => ({
  type: GET_POST_SUCCESS,
  payload,
});

export const get_post_errored = (error) => ({
  type: GET_POST_ERRORED,
  error,
});

export const get_post_details_requested = () => ({
  type: GET_POST_DETAILS_REQUESTED,
});

export const get_post_details_success = (payload) => ({
  type: GET_POST_DETAILS_SUCCESS,
  payload,
});

export const get_post_details_errored = (error) => ({
  type: GET_POST_DETAILS_ERRORED,
  error,
});

export const update_post_requested = () => ({
  type: UPDATE_POST_REQUESTED,
});

export const update_post_success = (payload) => ({
  type: UPDATE_POST_SUCCESS,
  payload,
});

export const update_post_errored = (error) => ({
  type: UPDATE_POST_ERRORED,
  error,
});
export const delete_post_requested = () => ({
  type: DELETE_POST_REQUESTED,
});

export const delete_post_success = (payload) => ({
  type: DELETE_POST_SUCCESS,
  payload,
});

export const delete_post_errored = (error) => ({
  type: DELETE_POST_ERRORED,
  error,
});

export const reset_post = () => ({
  type: RESET_POST,
});

export const create_post = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_post_requested());
  console.log(`Create post`);
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/articles`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("post created successfully");
        dispatch(showSnackbar("post created successfully", "success"));
        dispatch(create_post_success());
        dispatch(get_post("", activeOuid));
      })
      .catch((err) => {
        console.log("post creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("post creation Failed", "error"));
        dispatch(create_post_errored(err));
      });
  }
  return null;
};

export const update_post =
  (payload, activeOuid, postId) => async (dispatch) => {
    dispatch(update_post_requested());
    console.log(`update_post`);

    const { virtualcommunityApiEndpoint, credentials } =
      store.getState().jwt.api;
    if (virtualcommunityApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${virtualcommunityApiEndpoint}/articles/id/${postId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("post Updation Success");
          dispatch(showSnackbar("post Updated Successfully", "success"));
          dispatch(update_post_success());
        })
        .catch((err) => {
          console.log("post updation Failed");
          dispatch(showSnackbar("post updation Failed", "error"));
          dispatch(update_post_errored(err));
        });
    }
    return null;
  };

export const get_post = (query, activeOuid) => async (dispatch) => {
  dispatch(get_post_requested());
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`virtualcommunityApiEndpoint`, virtualcommunityApiEndpoint);
  console.log(`credentials`, credentials);

  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/articles${query}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get post Success", response.data);
        dispatch(get_post_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get post Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get post Failed", "error"));
        }
        dispatch(get_post_errored(err));
      });
  }
  return null;
};

export const get_post_details = (postId, activeOuid) => async (dispatch) => {
  dispatch(get_post_details_requested());
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`virtualcommunityApiEndpoint`, virtualcommunityApiEndpoint);
  console.log(`credentials`, credentials);

  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/articles/id/${postId}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get post Success", response.data);
        dispatch(get_post_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get post Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get post Failed", "error"));
        }
        dispatch(get_post_details_errored(err));
      });
  }
  return null;
};

export const delete_post = (activeOuid, postId) => async (dispatch) => {
  dispatch(delete_post_requested());
  console.log(`delete_note`);
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/articles/id/${postId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete post Success");
        dispatch(showSnackbar("post deleted successfully", "success"));
        dispatch(delete_post_success());
        dispatch(get_post("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete post Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete post", "error"));
        }
        dispatch(delete_post_errored(err));
      });
  }
  return null;
};
