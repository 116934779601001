import React, { useEffect } from "react";
import FeedCard from "./common/FeedCard";
import "./App.css";
import Header from "./Header";
import Footer from "./Footer";
import CompanyScrollCard from "./common/CompanyScrollCard";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import AddIcon from "@material-ui/icons/Add";
import {
  get_all_incubators,
  get_all_Orgunit,
} from "../../../actions/action-orgunit";
import { useDispatch, useSelector } from "react-redux";
import { get_post } from "./actions/action-post";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

export default function Home() {
  const dispatch = useDispatch();

  const { activeOuid } = store.getState().permission;
  const { orgunits } = useSelector((state) => state.orgunit);
  const { posts, showGlobalLoader } = useSelector((state) => state.post);
  console.log("posts", posts);

  const filtered = orgunits.filter((incubator) => {
    return incubator.data.type === "inc";
  });

  function renderFeedList(posts) {
    console.log("posts", posts);
    if (posts) {
      return posts.map((item) => (
        <FeedCard profile={true} key={item.id} item={item} />
      ));
    } else {
      <div>No posts</div>;
    }
  }

  function renderCompanyScrollList(filtered) {
    console.log("filtered", filtered);
    if (filtered) {
      return filtered.map((item) => (
        <CompanyScrollCard key={item.data.ouid} item={item} />
      ));
    } else {
      <div>No chats</div>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_all_incubators("ibc", activeOuid));
      await dispatch(get_post("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className="AppHomePage  container--narrow">
        <div className="company_scroll_card_wrapper">
          {filtered && renderCompanyScrollList(filtered)}
        </div>
        {posts && renderFeedList(posts)}
      </div>
      <div className="AppFloatingButtonWrapper">
        <button
          className=" circle_add_btn"
          onClick={() => history.push(`/${activeOuid}/add-feed`)}
        >
          <AddIcon />
        </button>
      </div>
      <CommonSnackBar />
      <Footer />
    </div>
  );
}
