import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import configureStore from "../../../../store/configureStore";
import TextField from "@material-ui/core/TextField";
import Loader from "../../../common/Loader";
import { showSnackbar } from "../../../../actions/action-snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import {
  delete_document_errored,
  delete_document_requested,
  delete_document_success,
  document_upload_errored,
  document_upload_requested,
  document_upload_success,
} from "../actions/action-document";

const store = configureStore();

const useStyles = makeStyles({
  Input: {
    fontSize: 14,
  },
  DialogText: {
    fontSize: 16,
  },
  CommentInputWrapper: {
    borderColor: "#dc281e",
    "& .MuiInput-underline": {
      "&:after": {
        borderColor: "#3b4f5a",
      },
    },
  },
  PrimaryButtonroot: {
    background: "#DC281E",
    padding: "5px 15px",
    fontSize: 12,
    "&:hover": {
      background: "#DC281E",
    },
  },
  SecondaryButtonroot: {
    borderColor: "#DC281E",
    marginRight: 15,
    fontSize: 12,
    padding: "5px 15px",
    color: "#DC281E",
    "&:hover": {
      borderColor: "#DC281E",
    },
  },
});

function ImageUploader(props) {
  const { handleUploadChange, activeOuid, activeOuidChain } = props;
  const [file, setFile] = useState();

  const classes = useStyles();
  const dispatch = useDispatch();

  const onFileChange = (event) => {
    console.log("File change");
    setFile(event.target.files[0]);
  };

  const onFileUpload = async (event) => {
    event.preventDefault();
    console.log(`onFileUpload`);
    await store.dispatch(document_upload_requested());
    console.log("showUploadLoader", store.getState().document.showUploadLoader);
    const { virtualcommunityApiEndpoint, credentials } =
      store.getState().jwt.api;
    if (virtualcommunityApiEndpoint && credentials) {
      const fileName = file.name;
      const type = file.type;
      const method = "get";
      const jwt = `${credentials.idToken.jwtToken}`;
      const url = `${virtualcommunityApiEndpoint}/uploads?filename=${fileName}`;
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, axiosHeader)
        .then(async (response) => {
          console.log("signed url get response : ", response.data.signedURL);
          await uploadFile(response.data.signedURL);
        })
        .catch((err) => {
          console.log("signed url get failed : ", err);
        });
    }
  };

  const uploadFile = async (signedUrlData) => {
    console.log(`uploadFile`, signedUrlData);
    const { virtualcommunityApiEndpoint, credentials } =
      store.getState().jwt.api;
    if (virtualcommunityApiEndpoint && credentials) {
      const method = "put";
      const url = signedUrlData;
      const body = file;
      const axiosHeader = {
        headers: {
          "Content-type": file.type,
        },
      };
      console.log("Url", url);
      return axios[method](url, body, axiosHeader)
        .then(async (response) => {
          console.log("s3 upload response cc : ", response);
          store.dispatch(
            showSnackbar("Document uploaded Successfully", "success")
          );
          console.log("response.config.url", response.config.url.split("?")[0]);
          await store.dispatch(
            document_upload_success(response.config.url.split("?")[0])
          );
          handleUploadChange();
          console.log(
            "showUploadLoader",
            store.getState().document.showUploadLoader
          );
        })
        .catch((err) => {
          store.dispatch(document_upload_errored());
          console.log("s3 upload failed : ", err);
        });
    }
  };

  const { showUploadLoader } = store.getState().document;

  return (
    <div>
      <div className="mt">
        {showUploadLoader ? <Loader /> : ""}

        <h3> Image Upload</h3>
        <div className="form-item-wrapper">
          <label className="image_input_upload_label" for="image_input_upload">
            <h4>Add Photo</h4>
          </label>
          {file && file.name}
          <input
            id="image_input_upload"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </div>
        {file && (
          <div className="image_upload_btn_wrap">
            <button className="blue_btn" onClick={onFileUpload}>
              Upload
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  document: state.document,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploader);

export const delete_file = (dKey, activeOuid) => async (dispatch) => {
  console.log("in delete_document()");
  dispatch(delete_document_requested());
  const { activeOuidChain } = store.getState().permission;
  console.log(`delete_document`);
  const { crmApiEndpoint, credentials } = store.getState().jwt.api;
  if (crmApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    const url = `${crmApiEndpoint}/uploads?objectKey=${dKey}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
        "x-ouidchain": activeOuidChain,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        dispatch(showSnackbar("Document Deleted Successfully", "success"));
        dispatch(delete_document_success());
      })
      .catch((err) => {
        console.log("Document Deletion Failed");
        dispatch(showSnackbar("Document Deletion Failed", "error"));
        dispatch(delete_document_errored(err));
      });
  }
  return null;
};
