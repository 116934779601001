import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import CompanyListing from "./CompanyListing";
import ChatList from "./ChatList";
import PostDetails from "./PostDetails";
import IncubatorProfile from "./IncubatorProfile";
import Search from "./Search";
import ChatScreen from "./ChatScreen";
import ChatWindow from "./ChatWindow";
import AddFeed from "./AddFeed";
import AddStartup from "./AddStartup";
import { get_all_channel } from "./actions/action-chat";
import EditFeed from "./EditFeed";

const store = configureStore();

export default function VirtualCommunityApp() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { channels, showGlobalLoader } = useSelector((state) => state.chat);

  console.log("channels!", channels);

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_all_channel(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  const { permissions } = store.getState().permission;
  return (
    <div className="EcomApp">
      <Switch>
        <Route exact path="/" render={(props) => <Home />} />
        <Route
          exact
          path="/:id/company-listing"
          render={(props) => <CompanyListing />}
        />
        <Route exact path="/:id/chat-list" render={(props) => <ChatList />} />
        <Route
          exact
          path="/:id/chat-screen/:id"
          render={(props) => <ChatScreen />}
        />
        <Route
          exact
          path="/:id/chat-window/:id"
          render={(props) => <ChatWindow />}
        />
        <Route
          exact
          path="/:id/post-details"
          render={(props) => <PostDetails />}
        />
        <Route
          exact
          path="/:id/incubator-profile/:id"
          render={(props) => <IncubatorProfile />}
        />
        <Route exact path="/:id/search" render={(props) => <Search />} />
        <Route exact path="/:id/add-feed" render={(props) => <AddFeed />} />
        <Route
          exact
          path="/:id/edit-feed/:id"
          render={(props) => <EditFeed />}
        />

        <Route
          exact
          path="/:id/add-startup"
          render={(props) => <AddStartup />}
        />
      </Switch>
    </div>
  );
}
