import React from "react";
import SearchField from "../../common/SearchField";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";

export default function Search() {
  return (
    <div>
      <HeaderInner pageTitle="Search " />

      <div className="HrmAppPage container--narrow mt">
        <div className="searchWrapper">
          <SearchField
            onChange={(e) => this.search(e.target.value.split(" "))}
          />
        </div>
        <ul className="search_category_wrap">
          <li className="search_category_item">Technology</li>
          <li className="search_category_item">Services</li>
          <li className="search_category_item">Decor</li>
          <li className="search_category_item">Technology</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}
