import React, { useEffect, useState } from "react";
import { Launcher } from "react-chat-window";
import Sockette from "sockette";
import configureStore from "../../../store/configureStore";
import { create_channel, get_chat_by_channel, reset_chat } from "./actions/action-chat";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../reducers";

let ws = null;

const store = configureStore();

const ChatWindow = (props) => {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  let url;
  let chatId;
  url = window.location.pathname.split("/");
  chatId = url[3];
  const params = new URL(window.location.href).searchParams;
  const actor = params.get("actor");
  const [messageList, setMessageList] = useState([]);
  const {channelChat , showGlobalLoader} = store.getState().chat;
  const [badge, setBadge] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  const { details } = store.getState().myProfile;
  const username = details.data.username;
  console.log("username", username);
  const { jwtToken } = store.getState().jwt.api.credentials.idToken;
  const { webSocketUrl } = store.getState().jwt.api;
  useEffect(
    () => {
      if (username)
        ws = new Sockette(
          `${webSocketUrl}?channel=${chatId}`,
          {
            timeout: 5e3,
            maxAttempts: 1,
            onopen: e => dispatch(get_chat_by_channel(chatId, activeOuid)),
            onmessage: e => onMessageReceied(e),
            onreconnect: e => console.log("Reconnecting...", e),
            onmaximum: e => console.log("Stop Attempting!", e),
            onclose: e => console.log("Closed!", e),
            onerror: e => console.log("Error:", e)
          }
        );
      // return function cleanup() {
      //   ws && ws.close();
      //   ws = null;
      // };      
    // channelChat !== [] && convertMessageFormat(channelChat);
    },
    [messageList]
  );

  const handleClick = () => {
    setIsOpen(!isOpen);
    dispatch(reset_chat());
    history.push(`/${activeOuid}/chat-list`);  
    setBadge(0);
  };

  const onMessageWasSent = (message) => {
    const newMessage = { ...message };
    if(channelChat.length === 0){
      console.log("Create and send message", channelChat);
      console.log("Activeid", activeOuid);
      console.log("actor", actor);
      const channelState = {
        channel: chatId,
        actors: [`${activeOuid.toLowerCase()}`, `${actor.toLowerCase()}`],
      }
      dispatch(create_channel(channelState, activeOuid));
    }
    console.log("newMessage", newMessage);
    ws.json({
      action: "sendmessage",
      data: {text: message},
      token: jwtToken,
      ouid: activeOuid
    });
    dispatch(get_chat_by_channel(chatId, activeOuid));
    console.log("Message send");
  };

  const onMessageReceied = (message) => {
    console.log("Recieved message", JSON.parse(message.data));
    let data = JSON.parse(message.data);
    let author = data.userName;
    console.log("author", author);

    data = data.data;
    console.log("data", data);
    const isMe = username === author ? "me" : "them";
    console.log("isMe", isMe);
    if (!isOpen) {
      setBadge(+badge + 1);
    }
    // dispatch(get_chat_by_channel(chatId, activeOuid));
    console.log("messageList", messageList)
    setMessageList([
      ...messageList,
      {
        author: isMe,
        data: data.text.data
      }
    ]);
  };
  // const convertMessageFormat = (channelChat) => {
  //   console.log("Convert message format");
  //   if(channelChat != null){
  //     let newData = channelChat.map((item) => (
  //       {
  //         author: item.chatMessage.userName === username ? "me" : "them",
  //         data: item.chatMessage.data.text.data,
  //         type: item.chatMessage.data.text.type
  //       }
  //     ));
  //     newData = newData.reverse();
  //     console.log("Message list", newData);
  //     setMessageList(newData);
  //     // return newData;
  //   }
  //   else{
  //     return []
  //   }
  // };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_chat_by_channel(chatId, activeOuid));
    };
    console.log("Messagelist", messageList);
    fetchData(dispatch);
    // convertMessageFormat(channelChat);
  }, [dispatch]);
  return (
    <div>
      <Launcher
        agentProfile={{
          teamName: chatId && chatId,
          imageUrl:
            "https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png",
        }}
        onMessageWasSent={onMessageWasSent}
        messageList={channelChat}
        handleClick={handleClick}
        isOpen={isOpen}
        showEmoji={false}
        newMessagesCount={badge}
      />
    </div>
    
  );
};

export default ChatWindow;
