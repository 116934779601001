import React from "react";
import profimg from "./images/pro.png";
import { Link } from "react-router-dom";
import HeaderInner from "./HeaderInner";
import postimg from "./images/detimg.png";
import Footer from "./Footer";

export default function PostDetails() {
  return (
    <div className="HrmAppPage container--narrow mt">
      <HeaderInner />
      <div className="post_details_wrap">
        <div className="post_details_media mb">
          <img src={postimg} />
        </div>
        <div className="post_details_profile_wrap mb">
          <div className="post_details_profile_img">
            <img src={profimg} />
            {/* <Avatar
              className={classes.avatar}
              style={{
                backgroundColor: "#214B87",
              }}
            >
              {props.item.contactFullName &&
                props.item.contactFullName.charAt(0)}
            </Avatar> */}
          </div>
          <div className="post_details_profile_details">
            <Link to="/">
              <div className="post_details_profile_name">GINSERV </div>
            </Link>
            <span className="post_details_profile_company">
              Global Incubation Services
            </span>
          </div>
        </div>
        <p className="post_details_desc mt">
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum.
        </p>
      </div>
      <Footer />
    </div>
  );
}
