import React from "react";
import SearchField from "../../common/SearchField";
import CompanyCard from "./common/CompanyCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";

export default function CompanyListing() {
  function renderCompanyList() {
    return <CompanyCard />;
  }

  return (
    <div>
      <HeaderInner />
      <div className=" HrmAppPage container--narrow mt">
        <div className="searchWrapper">
          <SearchField
            onChange={(e) => this.search(e.target.value.split(" "))}
          />
        </div>
        {renderCompanyList()}
      </div>
      <Footer />
    </div>
  );
}
