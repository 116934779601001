import React from "react";
import { Link } from "react-router-dom";
import profimg from "../images/pro.png";
import configureStore from "../../../../store/configureStore";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function ChatCard(props) {
  const { activeOuid } = store.getState().permission;
  const classes = useStyles();

  const filterActor = props.item.channelActors.filter((actor) => {
    return actor !== props.item.name;
  });
  console.log("filterActor", filterActor);
  var oppActor = filterActor[0];
  console.log("actor", oppActor);

  return (
    <>
      <Link
        to={`/${activeOuid}/chat-window/${props.item.channel}?actor=${oppActor}`}
        className="company_card_wrap"
      >
        <div className="company_card_img">
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214B87",
            }}
          >
            {/* {props.item.name && props.item.name.charAt(0)} */}
            {oppActor && oppActor.charAt(0)}
          </Avatar>
        </div>
        <div className="company_card_details_wrap">
          <div className="company_card_details_top">
            <div className="company_card_details_name">{oppActor ? oppActor.replace("-", " ") : props.item.channelActors[0].replace("-", " ")}</div>
            {/* <span className="company_card_details_tag">02:00 pm</span> */}
          </div>
          {props.item.unreadMessageCount > 0 && (
            <div className="chat_message_count">
              {props.item.unreadMessageCount}
            </div>
          )}
          
        </div>
      </Link>
    </>
  );
}
