import {
  CREATE_CHANNEL_REQUESTED,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_ERRORED,
  GET_ALL_CHANNEL_REQUESTED,
  GET_ALL_CHANNEL_SUCCESS,
  GET_ALL_CHANNEL_ERRORED,
  GET_CHAT_BY_CHANNEL_REQUESTED,
  GET_CHAT_BY_CHANNEL_SUCCESS,
  GET_CHAT_BY_CHANNEL_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_channel_requested = () => ({
  type: CREATE_CHANNEL_REQUESTED,
});

export const create_channel_success = () => ({
  type: CREATE_CHANNEL_SUCCESS,
});

export const create_channel_errored = (error) => ({
  type: CREATE_CHANNEL_ERRORED,
  error,
});

export const get_all_channel_requested = () => ({
  type: GET_ALL_CHANNEL_REQUESTED,
});

export const get_all_channel_success = (payload) => ({
  type: GET_ALL_CHANNEL_SUCCESS,
  payload,
});

export const get_all_channel_errored = (error) => ({
  type: GET_ALL_CHANNEL_ERRORED,
  error,
});

export const get_chat_by_channel_requested = () => ({
  type: GET_CHAT_BY_CHANNEL_REQUESTED,
});

export const get_chat_by_channel_success = (payload) => ({
  type: GET_CHAT_BY_CHANNEL_SUCCESS,
  payload,
});

export const get_chat_by_channel_errored = (error) => ({
  type: GET_CHAT_BY_CHANNEL_ERRORED,
  error,
});

export const reset_chat_by_channel = (payload) => ({
  type: GET_CHAT_BY_CHANNEL_ERRORED,
  payload,
});

export const create_channel = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_channel_requested());
  console.log(`Create channel`);
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/channels`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("channel created successfully");
        dispatch(showSnackbar("channel created successfully", "success"));
        dispatch(create_channel_success());
      })
      .catch((err) => {
        console.log("channel creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("channel creation Failed", "error"));
        dispatch(create_channel_errored(err));
      });
  }
  return null;
};

export const get_all_channel = (activeOuid) => async (dispatch) => {
  dispatch(get_all_channel_requested());
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`virtualcommunityApiEndpoint`, virtualcommunityApiEndpoint);
  console.log(`credentials`, credentials);

  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/channels?actor=${activeOuid}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get all channel Success", response.data);
        dispatch(get_all_channel_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get all channel Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get all channel Failed", "error"));
        }
        dispatch(get_all_channel_errored(err));
      });
  }
  return null;
};

export const get_chat_by_channel = (chatId, activeOuid) => async (dispatch) => {
  dispatch(get_chat_by_channel_requested());
  const { details } = store.getState().myProfile;
  const username = details.data.username;
  const { virtualcommunityApiEndpoint, credentials } = store.getState().jwt.api;
  if (virtualcommunityApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${virtualcommunityApiEndpoint}/chats?channel=${chatId}`;
    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get chat by channel Success", response);
        let newData = response.data.items.map((item) => (
          {
            author: item.chatMessage.userName === username ? "me" : "them",
            data: item.chatMessage.data.text.data,
            type: item.chatMessage.data.text.type
          }
        ));
        console.log("New message", newData);
        newData = newData.reverse();
        dispatch(get_chat_by_channel_success(newData));
      })
      .catch((err) => {
        console.log("Get chat by channel Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get chat by channel Failed", "error"));
        }
        dispatch(get_chat_by_channel_errored(err));
      });
  }
  return null;
};
export const reset_chat = () => async (dispatch) => {
  dispatch(get_chat_by_channel_success([]));
  return null;
};
