import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import { Link } from "react-router-dom";
import startupimg from "./images/startup.png";
import FeedCard from "./common/FeedCard";
import Footer from "./Footer";
import AddIcon from "@material-ui/icons/Add";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import { Avatar, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { create_channel } from "./actions/action-chat";
import {
  get_incubator_details,
  get_orgunit_details,
} from "../../../actions/action-orgunit";
import Loader from "../../common/Loader";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ControlCameraOutlined } from "@material-ui/icons";
import { get_post, get_post_by_id, reset_post } from "./actions/action-post";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
    width: 48,
    height: 48,
  },
}));

export default function IncubatorProfile() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { posts } = useSelector((state) => state.post);

  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { OrgunitDetails, showGlobalLoader } = useSelector(
    (state) => state.orgunit
  );
  console.log("OrgunitDetails", OrgunitDetails);

  const [state, setState] = React.useState({
    channel: "",
    actors: [],
  });

  console.log("state", state);
  function renderFeedList(posts) {
    console.log("posts", posts);
    if (posts) {
      return posts.map((item) => (
        <FeedCard profile={true} key={item.id} item={item} />
      ));
    } else {
      <div>No posts</div>;
    }
  }

  const handleSubmit = (org1, org2) => {
    console.log("org1", org1);
    console.log("org2", org2);
    let channelId = `${org1.replace("-", "")}-${org2.replace("-", "")}-general`;
    channelId = channelId.toLowerCase();
    console.log("channelId", channelId);
    // dispatch(create_channel(state, activeOuid));
    history.push(`/${activeOuid}/chat-window/${channelId}?actor=${org2}`);
  };

  useEffect(() => {
    console.log("inuseEffect");
    let incubatorId;
    incubatorId = window.location.pathname.split("/");
    incubatorId = incubatorId[3];
    console.log("incubatorid", incubatorId);
    const fetchData = async (dispatch) => {
      await dispatch(reset_post());
      await dispatch(get_incubator_details(incubatorId, activeOuid));
      await dispatch(get_post(`?ouid=${incubatorId}`, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div className="AppPage container--narrow mt">
      <HeaderInner
        pageTitle={OrgunitDetails.data && OrgunitDetails.data.name}
      />
      {showGlobalLoader ? (
        <Loader />
      ) : (
        <>
          <div className="incubator_profile_wrap">
            <div className="incubator_profile_top_wrap mb">
              <div className="post_details_profile_wrap mb">
                <div className="post_details_profile_img">
                  <Avatar
                    className={classes.avatar}
                    style={{
                      backgroundColor: "#214B87",
                    }}
                  >
                    {OrgunitDetails.data && OrgunitDetails.data.name.charAt(0)}
                  </Avatar>
                </div>
                <div className="post_details_profile_details">
                  <Link to="/">
                    <div className="post_details_profile_name">
                      {OrgunitDetails.data && OrgunitDetails.data.name}
                    </div>
                  </Link>
                  <span className="post_details_profile_company">
                    {OrgunitDetails.data && OrgunitDetails.data.businessType}
                  </span>
                </div>
              </div>
              {/* <p className="post_details_desc mt">
                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                blanditiis praesentium voluptatum.
              </p> */}
              {OrgunitDetails.data && (
                <ul className="profile_content_details_wrap mb">
                  <li className="profile_content_details_item">
                    {OrgunitDetails.data.address && (
                      <>
                        <div className="profile_content_details_label">
                          Address :
                        </div>
                        <div className="profile_content_details_value">
                          {OrgunitDetails.data.address},
                          {OrgunitDetails.data.state}
                        </div>
                      </>
                    )}
                  </li>
                  <li className="profile_content_details_item">
                    {OrgunitDetails.data.GSTNumber && (
                      <>
                        <div className="profile_content_details_label">
                          GST Number :
                        </div>
                        <div className="profile_content_details_value">
                          {OrgunitDetails.data.GSTNumber}
                        </div>
                      </>
                    )}
                  </li>
                  <li className="profile_content_details_item">
                    {OrgunitDetails.data.phoneNumber && (
                      <>
                        <div className="profile_content_details_label">
                          Phone Number :
                        </div>
                        <div className="profile_content_details_value">
                          {OrgunitDetails.data.phoneNumber}
                        </div>
                      </>
                    )}
                  </li>
                  <li className="profile_content_details_item">
                    {OrgunitDetails.data.officialMail && (
                      <>
                        <div className="profile_content_details_label">
                          Email :
                        </div>
                        <div className="profile_content_details_value">
                          {OrgunitDetails.data.officialMail}
                        </div>
                      </>
                    )}
                  </li>
                </ul>
              )}

              <div className="incubator_profile_btns_wrap">
                {OrgunitDetails.data && (
                  <button
                    className="incubator_profile_btn"
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleSubmit(activeOuid, OrgunitDetails.data.name)
                    }
                  >
                    Message
                  </button>
                )}
                {OrgunitDetails.data && (
                  <>
                    {OrgunitDetails.data.phoneNumber && (
                      <a
                        href={`tel:${OrgunitDetails.data.phoneNumber}`}
                        className="incubator_profile_btn"
                      >
                        Call
                      </a>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="incubator_profile_content_wrap">
            {/* <h5 className="startup_title">Startups</h5>
            <div className="incubator_profile_startup_wrap">
              <Link
                to={`/${activeOuid}/add-startup`}
                className="incubator_profile_startup_item incubator_profile_startup_item_add"
              >
                <AddIcon />
              </Link>
              <Link to="/" className="incubator_profile_startup_item">
                <img src={startupimg} />
              </Link>
              <Link to="/" className="incubator_profile_startup_item">
                <img src={startupimg} />
              </Link>
              <Link to="/" className="incubator_profile_startup_item">
                <img src={startupimg} />
              </Link>
            </div> */}
            {posts && renderFeedList(posts)}
          </div>
        </>
      )}

      <Footer />
    </div>
  );
}
