import React, {useDispatch} from "react";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import profimg from "../images/pro.png";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../../store/configureStore";

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
    width: 50,
    height: 50,
  },
}));

const store = configureStore();

export default function CompanyScrollCard(props) {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const { activeOuid } = store.getState().permission;

  return (
      <Link to={`/${activeOuid}/incubator-profile/${props.item.data.ouid}`} className="company_scroll_card_item">
        <div className="company_scroll_card_img">
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214B87",
            }}
          >
            {props.item.data.name && props.item.data.name.charAt(0)}
          </Avatar>
          
        </div>
        <span className="company_scroll_card_name">{props.item.data.name}</span>
      </Link>
  );
}
