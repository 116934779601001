import {
  CREATE_CHANNEL_REQUESTED,
  CREATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_ERRORED,
  GET_ALL_CHANNEL_REQUESTED,
  GET_ALL_CHANNEL_SUCCESS,
  GET_ALL_CHANNEL_ERRORED,
  GET_CHAT_BY_CHANNEL_REQUESTED,
  GET_CHAT_BY_CHANNEL_SUCCESS,
  GET_CHAT_BY_CHANNEL_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  channels: [],
  channelChat: [],
  error: {},
};

export function chat(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_CHANNEL_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_CHANNEL_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_CHANNEL_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ALL_CHANNEL_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ALL_CHANNEL_SUCCESS:
      return {
        ...state,
        channels: payload,
        showGlobalLoader: false,
      };

    case GET_ALL_CHANNEL_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case GET_CHAT_BY_CHANNEL_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CHAT_BY_CHANNEL_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        channelChat: payload,
      };

    case GET_CHAT_BY_CHANNEL_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
