import { Button, TextField } from "@material-ui/core";
import React, { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import DocumentUploader, { delete_file } from "./common/ImageUploader";
import configureStore from "../../../store/configureStore";
import {
  create_post,
  get_post_details,
  update_post,
} from "./actions/action-post";
import CommonSnackBar from "../../common/CommonSnackBar";
import CloseIcon from "@material-ui/icons/Close";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { convertFromHTML } from "draft-js";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
  },
}));

function EditFeed() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { documentUrl, showUploadLoader } = store.getState().document;
  const { postDetails, showGlobalLoader } = useSelector((state) => state.post);

  const [state, setState] = React.useState({
    title: "",
    summary: "dff Summary of the article",
    featuredImage: "",
    content: EditorState.createEmpty(),
    type: "",
    category: "startup",
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  const handleEditorChange = (editorState) => {
    setState({
      ...state,
      content: editorState,
    });
  };

  const handleUploadChange = async () => {
    console.log("handleUploadChange");
    setState({
      ...state,
      featuredImage: store.getState().document.documentUrl,
    });
  };

  const handleDelete = (event) => {
    event.preventDefault();
    console.log("Handle delete");
    var dUrl = new URL(state.featuredImage);
    var dKey = dUrl.pathname;
    dKey = dKey.slice(1);
    console.log("dKey", dKey);

    dispatch(delete_file(dKey, activeOuid));
    setState({
      ...state,
      featuredImage: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();    
    const postData = {
      ...state,
      content: draftToHtml(convertToRaw(state.content.getCurrentContent())),
    };    
    dispatch(update_post(postData, activeOuid, state.id));
    history.push(`/`);
  };  

  useEffect(() => {
    let postId;
    postId = window.location.pathname.split("/");
    postId = postId[3];
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_post_details(postId, activeOuid));

      console.log(store.getState().document.documentdetails);

    const blocksFromHTML = convertFromHTML(store.getState().post.postDetails.content);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    

      setState({
        ...state,
        id: store.getState().post.postDetails.id,
        title: store.getState().post.postDetails.title,
        content: EditorState.createWithContent(contentState),
        featuredImage: store.getState().post.postDetails.featuredImage,
        documentURL: store.getState().post.postDetails.documentURL,
        documentKey: store.getState().post.postDetails.documentKey,
        type: store.getState().post.postDetails.type,
      });
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Feed" />
      <div className="container--narrow AppPage mt">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="image_input_wrap">
              {state.featuredImage && (
                <>
                  <img
                    className="file_input_img_preview"
                    src={
                      state.featuredImage ? state.featuredImage : documentUrl
                    }
                  />
                  <button className="img_delete_btn" onClick={handleDelete}>
                    <CloseIcon />
                  </button>
                </>
              )}
              {state.featuredImage === "" && (
                <DocumentUploader
                  activeOuid={activeOuid}
                  activeOuidChain={activeOuidChain}
                  defaultState={state.upload}
                  handleUploadChange={handleUploadChange}
                />
              )}
            </div>

            <TextField
              id="title"
              label="Title"
              fullWidth
              variant="outlined"
              required
              className={classes.input}
              onChange={handleChange}
              defaultValue={state.title}
              value={state.title}
            />
            <TextField
              id="type"
              label="Type"
              fullWidth
              variant="outlined"
              required
              className={classes.input}
              onChange={handleChange}
              defaultValue={state.type}
              value={state.type}
            />            
            <div className="form-item-wrapper" style={{backgroundColor:"#FFF"}}>
              <Editor
                editorState={state.content}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"          
                onEditorStateChange={handleEditorChange}
                wrapperStyle={{
                  width: "unset",
                  fontSize:"13px",
                }}
                placeholder="Description"                                   
              />
            </div>
            <div className="AppFloatingButtonWrapper t-c">
              <button className="w-100  secondary_btn" type="submit">
                SAVE
              </button>
            </div>
          </form>
        )}
      </div>
      <CommonSnackBar />
      <Footer />
    </div>
  );
}

export default EditFeed;
