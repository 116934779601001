import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Avatar, Drawer } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { delete_post } from "../actions/action-post";
import { useDispatch } from "react-redux";
import { history } from "../../../../reducers";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
    width: 44,
    height: 44,
  },
}));

export default function FeedCard(props) {
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { activeOuid, activeOuidChain } = store.getState().permission;

  const handleDetele = () => {
    console.log("props.item.username delete", props.item.id);
    dispatch(delete_post(activeOuid, props.item.id));
  };

  return (
    <div className="feed_card_wrap mb">
      {props.profile && (
        <div className="feed_card_top mb">
          <div className="feed_card_top_left">
            <div className="feed_card_img">
              <Avatar
                className={classes.avatar}
                style={{
                  backgroundColor: "#214B87",
                }}
              >
                {props.item.ouid && props.item.ouid.charAt(0)}
              </Avatar>
            </div>
            <div className="feed_card_profile_details">
              <Link
                to={`/${activeOuid}/incubator-profile/${props.item.ouid}`}
                className="feed_card_profile_name"
              >
                {props.item.ouid.replace("-", " ")}
              </Link>
              {/* <div className="feed_card_profile_name">GINSERV</div> */}
              <span className="feed_card_company">{props.item.type}</span>
            </div>
          </div>
          <div className="feed_card_top_right">
            {activeOuid === props.item.ouid && (
              <>
                <button
                  className="option_btn"
                  onClick={() => setIsDrawerOPen(true)}
                >
                  <MoreVertIcon />
                </button>
              </>
            )}

            <Drawer
              className="edit_option_btn_drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => setIsDrawerOPen(false)}
            >
              <div className="edit_option_head">options</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <DeleteIcon onClick={() => handleDetele()} />
                  <span className="edit_option_tools_item_text">Delete</span>
                </li>

                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <EditIcon
                    onClick={() =>
                      history.push(`/${activeOuid}/edit-feed/${props.item.id}`)
                    }
                  />
                  <span className="edit_option_tools_item_text">Edit</span>
                </li>
              </ul>
            </Drawer>
          </div>
        </div>
      )}
      <div className="feed_media mb">
        <img src={props.item.featuredImage} />
      </div>
      <div className="feed_title">{props.item.title}</div>
      <div className="feed_desc" dangerouslySetInnerHTML={{ __html: props.item.content }}></div>
    </div>
  );
}
