import React, { useEffect } from "react";
import { get_all_channel } from "./actions/action-chat";
import ChatCard from "./common/ChatCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import Loader from "../../common/Loader";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";

const store = configureStore();

export default function ChatList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { channels, showGlobalLoader } = useSelector((state) => state.chat);

  function renderChatList(channels) {
    console.log("channels", channels);
    if (channels) {
      return channels.map((item) => <ChatCard key={item.id} item={item} />);
    } else {
      <div>No chats</div>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_all_channel(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner />
      <div className=" HrmAppPage container--narrow mt">
        {showGlobalLoader ? <Loader /> : renderChatList(channels)}
      </div>

      <Footer />
    </div>
  );
}
